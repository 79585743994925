import { combineReducers } from '@reduxjs/toolkit';
import auth from './auth/authSlice';
import template from './template/templateSlice';
import extraBlocks from './extraBlocks';
import toast from './common/toast';
import loading from './common/loading';
import email from './email';
import { apiSlice } from '@demo/services/api/api';

const appReducer = combineReducers({
  auth: auth.reducer,
  template: template.reducer,
  extraBlocks: extraBlocks.reducer,
  toast: toast.reducer,
  email: email.reducer,
  loading: loading.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
