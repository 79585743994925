import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Page from '@demo/components/Page';
import '@demo/styles/common.scss';
import '@demo/styles/globals.css';
// import { history } from './utils/history';
import Home from '@demo/pages/Home';
import Login from '@demo/pages/Login';
import PrivateRoutes from './components/PrivateRoutes';
import PublicRoutes from './components/PublicRoutes';
import Error404 from './pages/404';

const Editor = React.lazy(() => import('@demo/pages/Editor'));

function App() {
  return (
    <Page>
      <Suspense
        fallback={
          <div
            style={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img width='200px' src='/loading' alt='' />
            <p
              style={{
                fontSize: 24,
                color: 'rgba(0, 0, 0, 0.65)'
              }}
            >
              {/* Please wait a moment. */}
            </p>
          </div>
        }
      >
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path='/' element={<Home />} />
            <Route path='/editor' element={<Editor />} />
          </Route>
          <Route element={<PublicRoutes />}>
            <Route path='/login' element={<Login />} />
            <Route path='/error404' element={<Error404 />} />
          </Route>
        </Routes>
      </Suspense>
    </Page>
  );
}

export default App;
