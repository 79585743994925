import { useAppSelector } from '@demo/hooks/useAppSelector';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PublicRoutes = () => {
  const auth = useAppSelector('auth');
  return auth && auth.isLogged ? <Navigate to='/' /> : <Outlet />;
};

export default PublicRoutes;
