import { ITemplate } from '@demo/services/template';
import { AdvancedType, BasicType, BlockManager, IBlockData } from 'easy-email-core';
import { IEmailTemplate } from 'easy-email-editor';
import { createSlice } from '@reduxjs/toolkit';

export function getAdaptor(data: ITemplate): IEmailTemplate {
  // const content = JSON.parse(data.content.content) as IBlockData;
  const content = JSON.parse(data.source) as IBlockData;
  // const content = MjmlToJson(data.content);
  console.log('content get adaptor email', content);
  return {
    ...data,
    content,
    subject: data.subject,
    name: data.name,
    cc: data.cc,
    cc_name: data.cc_name,
    from_name: data.from_name,
    subTitle: ''
  };
}

export default createSlice({
  name: 'template',
  initialState: null as IEmailTemplate | null,
  reducers: {
    set: (state, action) => {
      return action.payload || null;
    },
    fetchDefaultTemplate: () => {
      console.log('entrei fetch default template');
      return {
        name: 'Template basic',
        subject: 'Welcome to Easy-email',
        subTitle: 'Nice to meet you!',
        content: BlockManager.getBlockByType(BasicType.PAGE).create({
          children: [BlockManager.getBlockByType(AdvancedType.WRAPPER).create()]
        })
      } as IEmailTemplate;
    }
  }
});
