import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
// import * as Sentry from '@sentry/browser';
// import { BrowserTracing } from '@sentry/tracing';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '@demo/store';
import { Provider } from 'react-redux';

if (process.env.NODE_ENV === 'production') {
  // Sentry.init({
  //   dsn: '',
  //   integrations: [new BrowserTracing()],
  //   tracesSampleRate: 1.0
  // });
}

const router = createBrowserRouter([{ path: '*', element: <App /> }]);

createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <RouterProvider router={router} />
    </PersistGate>
  </Provider>
);
