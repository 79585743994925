import { user } from '@demo/services/user';
import createSliceState from './common/createSliceState';
import toast from 'react-hot-toast';
import { UserStorage } from '@demo/utils/user-storage';
import { template } from '@demo/services/template';

interface User {
  inForgotPassword: boolean;
  inLogin: boolean;
  user?: any;
  templates?: any;
}

const initialState: User = {
  inForgotPassword: false,
  inLogin: true,
  user: null,
  templates: null
};

export default createSliceState({
  name: 'user',
  initialState: initialState,
  reducers: {
    set: (state, action) => {
      return action.payload;
    },
    setForgotPassword: (state, action) => {
      return { ...state, inForgotPassword: action.payload };
    },
    setLogin: (state, action) => {
      return { ...state, inLogin: action.payload };
    }
  },
  effects: {
    login: async (state, payload: any): Promise<User> => {
      const res = await user.login(payload);
      if (res.error) {
        toast(JSON.stringify(res.error));
        return { ...state, user: null };
      } else {
        await UserStorage.setAccount(res);

        const templates = await template.getAll();

        toast('Logado');
        return { ...state, user: res.data.user, templates: templates.data };
      }
    },
    getTemplates: async (state, payload): Promise<User> => {
      console.log('state get templates', state);
      console.log('payload get templates', payload);
      const res = await template.getAll();
      if (res.error) {
        toast(JSON.stringify(res.error));
        return { ...state };
      } else {
        return { ...state, templates: res.data };
      }
    }
  }
});
