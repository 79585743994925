import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { UserStorage } from '@demo/utils/user-storage';

export const axiosInstance = axios.create({
  baseURL: 'http://127.0.0.1:3333'
});

axiosInstance.interceptors.request.use(async function (config) {
  try {
    const userDataToken = await UserStorage.getToken();
    console.log('axios', userDataToken);
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.authorization = `bearer ${userDataToken}`;
  } catch (error) {
    // window.location.assign(LOGIN_ADDRESS);
  } finally {
    return config;
  }
});

axiosInstance.interceptors.response.use(
  function <T>(res: AxiosResponse<T>) {
    return new Promise((resolve, reject) => {
      return resolve(res);
    });
  },
  (error) => {
    console.log('axios error', error.response.data);
    // { status: error.response.status, message: error.response.data };
    return { data: { status: error.response.status, error: error.response.data } };
  }
);

export const request = {
  async get<T>(url: string, config?: AxiosRequestConfig | undefined) {
    return axiosInstance.get<T>(url, config).then((data) => data.data);
  },
  async post<T>(url: string, data?: any, config?: AxiosRequestConfig | undefined) {
    return axiosInstance.post<T>(url, data, config).then((data) => data.data);
  },
  async patch<T>(url: string, data?: any, config?: AxiosRequestConfig | undefined) {
    return axiosInstance.patch<T>(url, data, config).then((data) => data.data);
  },
  async delete<T>(url: string, data?: any, config?: AxiosRequestConfig | undefined) {
    return axiosInstance.delete<T>(url, config).then((data) => data.data);
  }
};
