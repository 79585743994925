import { request } from './axios.config';

export const user = {
  login(data) {
    return request.post<ILogin>('/login', data);
  },
  async get() {
    return request.get<IUser>('/users/info');
  }
};

export interface ILogin {
  data: {
    user: IUser;
    access_token: any;
  };
  status: any;
  error: any;
}

export interface IUser {
  id: number;
  whatsapp: string;
  name: string;
  type: string;
  avatar: string;
  domain: string;
  active: number;
  created_at: number;
  updated_at: number;
  last_login: number;
  deleted_at: number;
  concat: IConcat;
  theme: ITheme;
  data: any;
  access_token?: any;
}
interface IConcat {
  user_id: number;
  email: string;
  github: string;
  zhihu: string;
  weibo: string;
}
interface ITheme {
  user_id: number;
  music: string;
  color: string;
}
