import { method } from 'lodash';
import { apiSlice } from '../../services/api/api';

export const templateApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTemplates: builder.query({
      query: (data?) => ({
        url: '/templates',
        method: 'GET',
        params: data,
        providesTags: (_) => ['Template'],
        extraOptions: 'get-templates'
      })
    }),
    getTemplate: builder.mutation({
      query: ({ id, params, path = '/' }) => ({
        url: `${path == '/' ? `${path}templates/${id}` : `${path}templates`}`,
        method: 'GET',
        params: params,
        providesTags: (_) => ['Template'],
        extraOptions: 'get-template'
      })
    }),
    create: builder.mutation({
      query: (data) => ({
        url: '/templates',
        method: 'POST',
        body: { ...data },
        invalidatesTags: (_) => ['Template'],
        extraOptions: 'create-template'
      })
    }),
    update: builder.mutation({
      query: ({ id, data, path = '/' }) => ({
        url: `${path}templates/${id}`,
        method: 'PATCH',
        body: data,
        invalidatesTags: (_) => ['Template'],
        extraOptions: 'update-template'
      })
    }),
    delete: builder.mutation({
      query: ({ id }) => ({
        url: `/templates/${id}`,
        method: 'DELETE',
        invalidatesTags: (_) => ['Template'],
        extraOptions: 'delete-template'
      })
    }),
    uploadImages: builder.mutation({
      query: (data) => ({
        url: '/images',
        method: 'POST',
        body: data,
        formData: true
      })
    }),
    restoreTemplate: builder.mutation({
      query: (params) => ({
        url: '/editor/templates/default',
        method: 'GET',
        params: params
      })
    })
  })
});

export const {
  useGetTemplatesQuery,
  useGetTemplateMutation,
  useCreateMutation,
  useUpdateMutation,
  useDeleteMutation,
  useUploadImagesMutation,
  useRestoreTemplateMutation
} = templateApiSlice;
