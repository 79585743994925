import { useAppSelector } from '@demo/hooks/useAppSelector';
import { useLoading } from '@demo/hooks/useLoading';
import { useQuery } from '@demo/hooks/useQuery';
import { useInfoMutation } from '@demo/store/auth/authService';
import { setLoading, logout, setEditor, setIsLogged, setUser } from '@demo/store/auth/authSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoutes = () => {
  const auth = useAppSelector('auth');
  const [getInfo, { isLoading, status }] = useInfoMutation();
  const dispatch = useDispatch();
  const { app, token, user_id_ref, template_id_user } = useQuery();
  const [loading, setLoading] = useState(true);
  async function onSubmit() {
    const data: any = await getInfo({});
    console.log(data);
    if (!data.error) {
      dispatch(setUser(data.data));
    } else {
      dispatch(logout());
    }
    setLoading(false);
  }

  useEffect(() => {
    if (token) {
      dispatch(setEditor({ app, token, user_id_ref, template_id_user }));
    }
    onSubmit();
  }, []);

  if (loading) {
    return null;
  } else {
    return auth.user && auth.isLogged ? <Outlet /> : <Navigate to='/error404' />;
  }
};

export default PrivateRoutes;
