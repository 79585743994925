// import { setLogin } from '@/redux/slices/loginSlice';
import { useDispatch } from 'react-redux';
import { InputWithLabelLogin } from '../InputWithLabelLogin';
import React from 'react';
import login from '@demo/store/user';

export default function RegisterForm() {
  const dispatch = useDispatch();

  return (
    <section className='flex'>
      <form className='p-8 w-full'>
        <InputWithLabelLogin type='text'>Nome</InputWithLabelLogin>
        <InputWithLabelLogin type='text'>Celular</InputWithLabelLogin>
        <InputWithLabelLogin type='email'>Email</InputWithLabelLogin>
        <InputWithLabelLogin type='password'>Senha</InputWithLabelLogin>
        <div className='flex flex-row justify-between mt-4 items-center'>
          <button className='rounded-3xl text-sm font-semibold w-44 items-center bg-login-button-border h-11 text-text-active'>
            Experimente Grátis
          </button>
          <span
            className='text-sm font-semibold cursor-pointer'
            onClick={() => dispatch(login.actions.setLogin(true))}
          >
            Já possui uma conta ?
          </span>
        </div>
      </form>
    </section>
  );
}
