import { useAppSelector } from '@demo/hooks/useAppSelector';
import ForgotPasswordForm from '../../components/Layout/ForgotPasswordForm';
import LoginForm from '../../components/Layout/LoginForm';
import RegisterForm from '../../components/Layout/RegisterForm';
import auth from '@demo/store/auth/authSlice';
import React from 'react';
import { useDispatch } from 'react-redux';

export default function Login() {
  const inLogin = useAppSelector('auth')?.inLogin;
  const inForgotPassword = useAppSelector('auth')?.inForgotPassword;
  const activeBorder = 'border-b-2 border-solid border-login-button-border';
  const dispatch = useDispatch();

  return (
    <article className='flex items-center justify-center min-h-screen p-2 md:p-4 w-full'>
      <div className='flex bg-bg-login max-w-w-login min-h-h-login overflow-hidden relative w-full flex-col md:flex-row'>
        <div className='w-full md:w-1/2 flex flex-col items-center p-8 justify-between'>
          <div className='flex justify-center items-center w-full h-full'>
            <img
              src='https://app.ovni.com.br/assets/images/logo_ovni.svg'
              className='max-w-full p-8 pr-0 max-h-44 md:max-h-h-login-right h-auto border-none'
            />
          </div>
          <a href='https://empreender.com.br' className='flex w-fit my-1 mx-auto p-0'>
            <img
              src='https://empreender.nyc3.cdn.digitaloceanspaces.com/static/empreender.svg'
              className='h-2 max-w-full border-none'
            />
          </a>
        </div>
        <div className='w-full md:w-1/2 flex-col min-h-h-login flex'>
          <div className='flex mt-8 py-0 px-8'>
            <span
              className={
                'flex text-sm mr-4 py-3 px-0 font-semibold cursor-pointer ' +
                (inLogin && !inForgotPassword ? activeBorder : '')
              }
              onClick={() => {
                dispatch(auth.actions.setLogin(true));
                dispatch(auth.actions.setForgotPassword(false));
              }}
            >
              Login
            </span>
            <span
              className={
                'flex text-sm mr-4 py-3 px-0 font-semibold cursor-pointer ' +
                (!inLogin && !inForgotPassword ? activeBorder : '')
              }
              onClick={() => {
                dispatch(auth.actions.setLogin(false));
                dispatch(auth.actions.setForgotPassword(false));
              }}
            >
              Registar
            </span>
          </div>
          {inForgotPassword ? <ForgotPasswordForm /> : inLogin ? <LoginForm /> : <RegisterForm />}
          <div className='flex items-center justify-around py-7 md:p-8 '>
            <a href='#' className='text-xss uppercase font-bold'>
              Política de Privacidade
            </a>
            <a href='#' className='text-xss uppercase font-bold'>
              Política de Cookies
            </a>
            <a href='#' className='text-xss uppercase font-bold'>
              Termos e Condições
            </a>
          </div>
        </div>
      </div>
    </article>
  );
}
