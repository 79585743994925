import { IUser, user } from '@demo/services/user';

const sessionKey = 'session-key';
const tokenKey = 'token-key';

export class UserStorage {
  static async getAccount(): Promise<IUser> {
    const res = await user.get();
    console.log('get account user storage', res);
    // if (!res) {
    //   const account = await user.login(USER);
    //   console.log('account', account.data.access_token);
    //   window.sessionStorage.setItem(sessionKey, JSON.stringify(account.data.user));
    //   window.localStorage.setItem(tokenKey, account.data.access_token?.token);
    //   return account.data.user;
    // }
    return res;
  }

  static async setAccount(data) {
    window.sessionStorage.setItem(sessionKey, JSON.stringify(data.data.user));
    window.localStorage.setItem(tokenKey, data.data.access_token?.token);
  }

  static async getToken() {
    return window.localStorage.getItem(tokenKey);
  }

  static setToken(token: string) {
    window.localStorage.setItem(tokenKey, token);
  }

  static logout() {
    window.localStorage.setItem(tokenKey, '');
    window.sessionStorage.setItem(sessionKey, '');
  }
}
