import { createSlice } from '@reduxjs/toolkit';
interface User {
  inForgotPassword: boolean;
  inLogin: boolean;
  user?: any;
  templates?: any;
  app: string | null;
  user_id_ref: string | null;
  template_id_user: string | null;
  isLogged: any;
  loading: any;
}

const initialState: User = {
  inForgotPassword: false,
  inLogin: true,
  user: {
    access_token: {
      type: 'bearer',
      token: null
    }
  },
  isLogged: false,
  loading: null,
  templates: null,
  app: null,
  user_id_ref: null,
  template_id_user: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLogged = true;
      state.loading = false;
    },
    setTemplates: (state, action) => {
      state.templates = action.payload;
    },
    setForgotPassword: (state, action) => {
      return { ...state, inForgotPassword: action.payload };
    },
    setLogin: (state, action) => {
      return { ...state, inLogin: action.payload };
    },
    setIsLogged: (state, action) => {
      return { ...state, isLogged: action.payload };
    },
    remove: (state, action) => {
      console.log(action.payload);
      return { ...state, templates: state.templates.filter((i) => i.id !== action.payload.id) };
    },
    setEditor: (state, action) => {
      const { app, user_id_ref, template_id_user, token } = action.payload;
      state.app = app;
      state.user_id_ref = user_id_ref;
      state.template_id_user = template_id_user;
      state.user.access_token.token = token;
      state.loading = true;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    logout: () => {
      return initialState;
    }
  }
});

export const {
  setUser,
  setForgotPassword,
  setLogin,
  logout,
  remove,
  setEditor,
  setIsLogged,
  setLoading
} = authSlice.actions;

export default authSlice;
