import { useAppSelector } from '@demo/hooks/useAppSelector';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Frame from '@demo/components/Frame';
// import templateList from '@demo/store/templateList';
import { Button } from '@arco-design/web-react';
import { CardItem } from './components/CardItem';
import { Stack } from '@demo/components/Stack';
// import { history } from '@demo/utils/history';
import { pushEvent } from '@demo/utils/pushEvent';
// import templates from '@demo/config/templates.json';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, persistor } from '@demo/store';
import auth from '@demo/store/auth/authSlice';
import { useGetTemplatesQuery } from '@demo/store/template/templateService';
// import { apiSlice } from '@demo/services/api/api';

export default function Home(props) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const userData = useAppSelector('auth');

  // dispatch(apiSlice.util.resetApiState());

  const { data, refetch } = useGetTemplatesQuery({});

  useEffect(() => {
    // refetch();
    if (data) {
      dispatch(auth.actions.setTemplates(data.data));
    }
  }, [data]);

  return (
    <Frame
      title='Templates'
      primaryAction={
        <Button
          onClick={() => {
            pushEvent({ event: 'Create' });
            navigate('/editor');
          }}
        >
          Criar
        </Button>
      }
      secondAction={
        <Button
          onClick={() => {
            pushEvent({ event: 'Logout' });
            persistor.purge();
            dispatch({ type: 'LOGOUT' });
            navigate('/login');
          }}
        >
          Logout
        </Button>
      }
    >
      <>
        <Stack>
          {userData?.templates
            ? [...userData.templates].map((item) => <CardItem data={item} key={item.id} />)
            : ''}
        </Stack>
      </>
    </Frame>
  );
}
