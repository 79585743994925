import { apiSlice } from '../../services/api/api';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: '/login',
        method: 'POST',
        body: { ...data }
      }),
      invalidatesTags: ['Auth']
    }),
    info: builder.mutation({
      query: () => ({
        url: '/users/info',
        method: 'GET',
        extraOptions: 'get-user-info'
      }),
      invalidatesTags: ['Auth']
    })
  })
});

export const { useLoginMutation, useInfoMutation } = authApiSlice;
