import { request } from './axios.config';

const ENDPOINT_IMAGE = 'http://127.0.0.1:3333/images';

export const common = {
  async uploadImage(file: File | Blob): Promise<string> {
    const data = new FormData();
    data.append('image', file);

    const res = await request.post<{ url: string }>(ENDPOINT_IMAGE, data);
    console.log(res);
    return res.url;
  },
  uploadByUrl(url: string) {
    return request.get<string>('/upload/user/upload-by-url', {
      params: {
        url
      }
    });
  },
  getMenu(): Promise<IAppMenuItem[]> {
    return Promise.resolve([
      {
        name: '数据模板',
        icon: 'bar-chart',
        isOpen: true,
        children: [
          {
            name: '数据模板',
            url: '/'
          }
        ]
      }
    ]);
  },
  sendTestEmail(data: { toEmail: string; subject: string; html: string; text: string }) {
    return request.post('/email/user/send', {
      to_email: data.toEmail,
      subject: data.subject,
      text: data.text,
      html: data.html
    });
  }
};

export interface IAppMenuItem {
  name: string;
  url?: string;
  icon: string;
  isOpen?: boolean;
  children: IAppSubMenuItem[];
}

export interface IAppSubMenuItem {
  name: string;
  url: string;
  isOpen?: boolean;
}
