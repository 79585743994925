// import { Api } from '@/providers/Api';
// import { RootState } from '@/redux';
// import { useLoginUserMutation } from '@/redux/apiSlices/userApi';
// import { setForgotPassword } from '@/redux/slices/loginSlice';
import { LoginResolver } from '../../../validations/LoginResolver';
import { useForm } from 'react-hook-form';
import { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { InputWithLabelLogin } from '../InputWithLabelLogin';
import { useLoginMutation } from '@demo/store/auth/authService';
import auth from '@demo/store/auth/authSlice';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@demo/hooks/useAppSelector';
import { AppDispatch } from '@demo/store';

interface IformLogin {
  email: string;
  password: string;
}

export default function LoginForm() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const dataUser = useAppSelector('auth');
  const [login] = useLoginMutation();
  const formMethods = useForm<IformLogin>({ resolver: LoginResolver });
  const {
    formState: { errors },
    register,
    handleSubmit,
    reset
  } = formMethods;

  useEffect(() => {
    if (dataUser.isLogged) navigate('/');
    return;
  }, [dataUser]);

  async function onSubmit(body) {
    const data = await login(body).unwrap();
    if (data) {
      dispatch(auth.actions.setUser(data));
    }
    // dispatch(authApiSlice.util.resetApiState());
  }

  return (
    <section className='flex'>
      <form className='p-8 w-full' onSubmit={handleSubmit(onSubmit)}>
        <InputWithLabelLogin type='email' register={register('email')}>
          Email
        </InputWithLabelLogin>
        <p className='text-bg-google-bg text-xs text-right'>{errors.email?.message}</p>
        <InputWithLabelLogin type='password' register={register('password')}>
          Senha
        </InputWithLabelLogin>
        <p className='text-bg-google-bg text-xs text-right'>{errors.password?.message}</p>
        <div className='flex flex-row justify-between mt-4 items-center'>
          <button className='rounded-3xl text-sm font-semibold w-24 items-center bg-login-button-border h-11 text-text-active'>
            Login
          </button>
          <span
            className='text-sm font-semibold cursor-pointer'
            onClick={() => dispatch(auth.actions.setForgotPassword(true))}
          >
            Esqueceu sua senha ?
          </span>
        </div>
        <div className='flex items-center flex-col justify-center mt-5'>
          <div className='float-left w-full mt-6 mx-0 mb-6 text-center relative'>
            <span className='w-auto float-none inline-block bg-bg-login py-px px-5 z-1 relative text-xs font-medium capitalize'>
              Ou Acesse com
            </span>
          </div>
          <ul className='flex gap-4 list-none items-center'>
            <li>
              <a
                href='#'
                className='text-xs font-semibold w-28 my-1 mx-0 h-9 leading-9 rounded-3xl text-center inline-block bg-bg-facebook-bg'
              >
                <i className='h-9 w-9 leading-9 float-left rounded-3xl bg-bg-facebook'>
                  <span className='box-border m-0 p-0 text-bg-login font-font-awesome'>f</span>
                </i>
                <span className='text-bg-login'>Facebook</span>
              </a>
            </li>
            <li>
              <a
                href='#'
                className='text-xs font-semibold w-28 my-1 mx-0 h-9 leading-9 rounded-3xl text-center inline-block bg-bg-google-bg'
              >
                <i className='h-9 w-9 leading-9 float-left rounded-3xl bg-bg-google'>
                  <span className='box-border m-0 p-0 text-bg-login font-font-awesome'>G</span>
                </i>
                <span className='text-bg-login'>Google</span>
              </a>
            </li>
            <li>
              <a
                href='#'
                className='text-xs font-semibold w-28 my-1 mx-0 h-9 leading-9 rounded-3xl'
              >
                <img src='/img/empreender-plus.svg' style={{ height: '36px' }} />
              </a>
            </li>
          </ul>
        </div>
      </form>
      <Toaster />
    </section>
  );
}
