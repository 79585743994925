import { request } from './axios.config';

export const template = {
  async get(id: number | string, userId: number): Promise<ITemplate> {
    return request.get<ITemplate>(`/templates/${id}`, {
      params: {
        id: id,
        user_id: userId
      }
    });
  },
  async getAll(params?): Promise<ListResponse<ITemplate>> {
    return request.get<ListResponse<ITemplate>>(`/templates`, {
      params
    });
  },
  async add(data): Promise<ITemplate> {
    return request.post<ITemplate>('/templates', data);
  },
  async update(id: number, data): Promise<ITemplate> {
    return request.patch<ITemplate>(`/templates/${id}`, data);
  },
  async delete(id: number): Promise<string> {
    return request.delete(`/templates/${id}`);
  }
};

export interface ListResponse<T> {
  json: T[];
  count: number;
  data: any;
  error?: any;
}

interface content {
  id: number;
  content: string;
}

export interface ITemplate {
  cc_name: any;
  cc: any;
  name: string;
  app: string;
  id: number;
  header?: string;
  footer?: string;
  subject: string;
  user_id: number;
  category_id: number;
  tags: { tag_id: number }[]; // 由于懒得写接口，这个接口是拿之前的，其实不需要数组
  picture: string;
  title: string;
  summary: string;
  secret: number;
  readcount: number;
  updated_at: number;
  created_at: number;
  level: number;
  content: content | any;
  source: any;
  from_name: string;
  error?: any;
}
