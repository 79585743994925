import { ITemplate } from '@demo/services/template';
import React, { useCallback } from 'react';
import { IconEdit, IconDelete } from '@arco-design/web-react/icon';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { Popconfirm } from '@arco-design/web-react';
import { Link, useNavigate } from 'react-router-dom';
import template from '@demo/store/template';
import { useDispatch } from 'react-redux';
// import templateList from '@demo/store/templateList';
import { pushEvent } from '@demo/utils/pushEvent';
import { getLoadingByKey, useLoading } from '@demo/hooks/useLoading';
import { Loading } from '@demo/components/loading';
import { useCreateMutation, useDeleteMutation } from '@demo/store/template/templateService';
import { remove } from '@demo/store/auth/authSlice';
interface CardItemProps {
  data: ITemplate;
}

export function CardItem(props: CardItemProps) {
  const { data } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteTemplate] = useDeleteMutation();
  const [createTemplate] = useCreateMutation();
  // const { refetch: refetchTemplates } = useGetTemplatesQuery({});

  const loading = useLoading(['create-template', 'delete-template']);

  const onDelete = useCallback(async () => {
    await deleteTemplate({ id: data.id });
    dispatch(remove({ id: data.id }));
    // dispatch(
    //   template.actions.removeById({
    //     id: data.id,
    //     _actionKey: data.id,
    //     success() {
    //       dispatch(templateList.actions.fetch(undefined));
    //     }
    //   })
    // );
  }, [data, dispatch]);

  const onDuplicate: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    async (ev) => {
      ev.preventDefault();
      const template = await createTemplate(data).unwrap();

      navigate(`/editor?id=${template.data.id}`);
      // dispatch(
      //   template.actions.duplicate({
      //     template: data,
      //     _actionKey: data.id,
      //     success(id) {
      //       navigate(`/editor?id=${id}`);
      //     }
      //   })
      // );
    },
    [data, dispatch, navigate]
  );

  return (
    <div
      key={data.id}
      className={styles.templeteItem}
      style={{ backgroundImage: `url(${data.picture})` }}
    >
      <div className={styles.bottom}>
        <div className={styles.title}>Name: {data.name}</div>
        <div className={styles.title}>Date {dayjs(data.created_at).format('DD-MM-YYYY')}</div>
      </div>
      <div className={styles.mask}>
        {loading ? (
          <div className={styles.listBottom}>
            <Loading loading color='#ffffff' />
          </div>
        ) : (
          <div className={styles.listBottom}>
            <div className={styles.listItem}>
              <Popconfirm
                title='Are you want to delete it?'
                onConfirm={onDelete}
                okText='Ok'
                cancelText='Cancel'
              >
                <IconDelete />
                &nbsp;Delete
              </Popconfirm>
            </div>
            <div className={styles.listItem}>
              <Link
                to={`/editor?id=${data.id}`}
                onClick={() =>
                  pushEvent({
                    event: 'Edit',
                    payload: { id: data.id, title: data.title }
                  })
                }
              >
                <IconEdit />
                &nbsp;Edit
              </Link>
            </div>
            <div className={styles.listItem}>
              <a onClick={onDuplicate}>Duplicate</a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
