import React from 'react';
import ListBox from '../ListBox';

interface Props {
  type?: 'text' | 'password' | 'number' | 'email' | 'list';
  variant?: 'default' | 'dark' | 'primary' | 'light';
  onClick?: VoidFunction;
  children: React.ReactNode;
  placeholder?: string;
  styles?: string;
  register?: any;
}

export function InputWithLabelLogin({ children, type, placeholder, styles, register }: Props) {
  let style = 'w-full flex flex-col ';
  let styleInput =
    'mb-3 bg-border-menu h-11 border-0 border-none rounded-3xl p-4 overflow-visible outline-none';

  style += styles;
  return (
    <div className={style}>
      <label className='mb-3 items-center flex text-sm font-semibold'>{children}</label>
      {type != 'list' ? (
        <input type={type} placeholder={placeholder} className={styleInput} {...register} />
      ) : (
        <ListBox />
      )}
    </div>
  );
}

{
  /* <div className="w-full flex flex-col">
<label className="mb-3 items-center flex text-sm font-semibold">E-mail</label>
<input className="mb-3 bg-border-menu h-11 border-0 border-none rounded-3xl p-4 overflow-visible outline-none" type="email" name="email"></input>
</div> */
}
