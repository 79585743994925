import './axios.config';
import { common } from '@demo/services/common';
import { template } from './template';
import { user } from './user';

const services = {
  common,
  template,
  user
};

export default services;
