import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const LoginSchema = yup.object({
    email: yup
        .string()
        .email('Preenche um e-mail válido')
        .required('Preenche o e-mail'),
    password: yup.string().required('Preenche a senha'),
});

export const LoginResolver = yupResolver(LoginSchema);
