import React from 'react';

export default function Error404() {
  return (
    <article className='flex items-center justify-center min-h-screen p-2 md:p-4 w-full'>
      <div
        className='w-full md:w-1/2 flex flex-col items-center p-8 justify-between'
        style={{ fontSize: '22px' }}
      >
        Em Manutenção
      </div>
    </article>
  );
}
