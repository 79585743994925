import login from '@demo/store/user';
import React from 'react';
import { useDispatch } from 'react-redux';

export default function ForgotPasswordForm() {
  const dispatch = useDispatch();

  return (
    <section className='flex'>
      <form className='p-8 w-full'>
        <div className='w-full flex flex-col'>
          <label className='mb-3 items-center flex text-sm font-semibold'>E-mail</label>
          <input
            className='mb-3 bg-border-menu h-11 border-0 border-none rounded-3xl p-4 overflow-visible outline-none'
            type='email'
            name='email'
          ></input>
        </div>
        <div className='flex flex-row justify-between mt-4 items-center'>
          <button className='rounded-3xl text-sm font-semibold w-44 items-center bg-login-button-border h-11 text-text-active'>
            Recuperar Senha
          </button>
          <span
            className='text-sm font-semibold cursor-pointer'
            onClick={() => dispatch(login.actions.setForgotPassword(false))}
          >
            Voltar
          </span>
        </div>
      </form>
    </section>
  );
}
