import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '@demo/store/reducers';
import { logout } from '../../store/auth/authSlice';
import { toast } from 'react-hot-toast';
import loading from '@demo/store/common/loading';

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_BASE_URL ? import.meta.env.VITE_BASE_URL?.toString() : '',
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const { auth } = getState() as RootState;
    if (auth && auth.user && auth.user.access_token) {
      const token = auth?.user?.access_token;
      if (token) {
        headers.set('authorization', `${token.type} ${token.token}`);
      }
    }

    return headers;
  }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  if (args.extraOptions) {
    api.dispatch(loading.actions.startLoading(args.extraOptions));
  }

  let result: any = await baseQuery(args, api, extraOptions);
  // console.log('result', result);
  if (result.error && result.error.data) {
    const response: any = result.error.data;
    toast(response.error);
  } else {
    if (result.data.message) {
      toast(result.data.message);
    }
  }

  // console.log('resu error', result.error?.data);
  // console.log('resu status', result.error?.status);
  if (result?.error?.status === 401) {
    // console.log('sending refresh token');
    // send refresh token to get new access token
    // const refreshResult = await baseQuery('/refresh', api, extraOptions);
    // console.log(refreshResult);
    // if (refreshResult?.data) {
    //   const user = api.getState().auth.user;
    //   // store the new token
    //   api.dispatch(setUser({ ...refreshResult.data, user }));
    //   // retry the original query with new access token
    //   result = await baseQuery(args, api, extraOptions);
    // } else {
    // api.dispatch(logout());
    // }
  }
  if (args.extraOptions) {
    api.dispatch(loading.actions.endLoading(args.extraOptions));
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Auth', 'Template'],
  endpoints: (builder) => ({})
});
